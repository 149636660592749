import React from "react"
import ImageGallery from 'react-image-gallery';
import ReedmakerConfig from "../../content/reedmaker.yaml"

const Gallery = () => {
  const { content } = ReedmakerConfig
  const images = content.gallery.urls.map(url => ({ original: url, thumbnail: url }));

  return (
    <>
      <ImageGallery items={images} />
    </>
  )
}

export default Gallery
