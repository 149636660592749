import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from '../components/gallery'
import {getClassNames, getImage, wpContentParse} from '../util'

const DefaultPage = (props) => {
    const { pageContext } = props
    const { title, acfGlobalPages, featuredImage } = pageContext.node 
    const wpContent = wpContentParse(pageContext)
    const layoutClasses = getClassNames(acfGlobalPages.sidebarLeft, acfGlobalPages.sidebarRight)
    const Image = getImage(featuredImage, pageContext.plugins.wpImage)

    return (
        <Layout>
            <SEO title={title} description={acfGlobalPages.metaDescription} />
            <div className="container mx-auto p-6 py-16">

                <div className="flex flex-wrap">

                    { layoutClasses.sidebarLeft &&
                    <section className={`${layoutClasses.sidebarLeft} p-3 pt-8`}>
                        <div className="md:border-r p-4">
                            <div className="content-block sidebar-left-one">{wpContent.leftOne}</div>
                            <div className="content-block sidebar-left-two">{wpContent.leftTwo}</div>
                        </div>
                    </section>
                    }

                    <section className={`${layoutClasses.main} p-3`}>                  
                        <h1 className="font-bold text-5xl md:text-6xl mb-3 text-primary">
                            {title}
                        </h1>
                        <div id="main-content-block">
                            {Image}
                            <section className="content-block">
                                {wpContent.main}
                                <Gallery />
                            </section>
                        </div>
                    </section>

                    { layoutClasses.sidebarRight &&
                    <section className={`${layoutClasses.sidebarRight} p-3 pt-8`}>
                        <div className="md:border-l p-4">
                            <div className="content-block sidebar-right-one">{wpContent.rightOne}</div>
                            <div className="content-block sidebar-right-two">{wpContent.rightTwo}</div>
                        </div>
                    </section>
                    }

                </div>

            </div>
      </Layout>
    )
}

export default DefaultPage